import Vue from "vue";
import App from "./App.vue";
import Meta from "vue-meta";
import router from "./router";
import * as VueGoogleMaps from "vue2-google-maps";
import KsVueScrollmagic from "ks-vue-scrollmagic";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAK9obQkVrCQifruWK2z-H2Z_UcWxOWZNg"
  }
});
Vue.use(Meta);
Vue.use(KsVueScrollmagic);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
