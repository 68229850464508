<template>
  <div>
    <footer>
      <div class="fbg">
        <div id="footer-bg-graphic"></div>
      </div>
      <div id="wave-footer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style="height: 100%; width: 100%;"
        >
          <path
            d="M-44.86,41.94 C238.99,95.22 262.69,34.03 502.54,53.78 L500.00,150.00 L-48.24,261.02 Z"
            style="stroke: none; fill: #E77600;"
          ></path>
        </svg>
      </div>
      <div class="footer-top">
        <h3>Kontakt</h3>
        <div class="footer-containers">
          <ul class="container container-business-data">
            <li>Kiki transporti - obrt za prijevoz</li>
            <li>vl. Kristijan Petrak</li>
            <li>Gorička ulica 12/1</li>
            <li>49218 Pregrada</li>
          </ul>
          <ul class="container container-contact">
            <li>
              <img src="@/assets/img/phone-icon.svg" alt="Phone Icon" />
              <span>+385 91 3778 411</span>
            </li>
            <li>
              <img src="@/assets/img/mail-icon.svg" alt="Mail Icon" />
              <span>kiki@kikitransporti.hr</span>
            </li>
            <li>
              <img src="@/assets/img/oib-icon.svg" alt="Oib Icon" />
              <span>HR8786039802</span>
            </li>
          </ul>
          <ul class="container container-social">
            <li>
              <a href="https://www.facebook.com/kikitransporti" target="_blank">
                <img src="@/assets/img/facebook-icon.svg" alt="Facebook Icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
    <span class="copyright">Copyright © {{ year }}. Kiki transporti</span>
  </div>
</template>

<script>
export default {
  name: "footer-kiki",
  data() {
    return {
      year: ""
    };
  },
  mounted() {
    this.year = new Date().getFullYear();
  }
};
</script>
