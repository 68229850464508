<template>
  <div id="app">
    <router-view></router-view>
    <mobile-menu></mobile-menu>
  </div>
</template>

<script>
import MobileMenu from "@/components/MobileMenu.vue";

export default {
  name: "App",
  components: {
    MobileMenu
  }
};
</script>

<style lang="scss">
#app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@import "@/assets/scss/style.scss";
</style>
