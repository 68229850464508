<template>
  <div id="menu-mobile">
    <header>
      <router-link to="/" @click.native="hideMenu()">
        <img
          src="@/assets/img/logo-dark.svg"
          alt="Kiki Transporti Logo"
          class="logo-menu-mobile"
        />
      </router-link>
      <div class="hamburger-close" @click="hideMenu()">
        <img src="@/assets/img/x-icon.svg" alt="Close Hamburger Menu Icon" />
      </div>
    </header>
    <nav>
      <router-link
        to="/"
        exact
        @click.native="hideMenu()"
        active-class="active-link"
        >Naslovna</router-link
      >
      <router-link
        to="/ekspres-prijevoz"
        @click.native="hideMenu()"
        active-class="active-link"
        >Ekspres prijevoz</router-link
      >
      <router-link
        to="/organizacija-prijevoza"
        @click.native="hideMenu()"
        active-class="active-link"
        >Organizacija prijevoza</router-link
      >
      <router-link
        to="/kontakt"
        @click.native="hideMenu()"
        active-class="active-link"
        >Kontakt</router-link
      >
    </nav>
    <div class="menu-footer">
      <div class="left">
        <img src="@/assets/img/phone-icon-black.svg" alt="Phone Black Icon" />
        <span>+385 91 3778 411</span>
      </div>
      <div class="right">
        <img src="@/assets/img/mail-icon-black.svg" alt="Mail Black Icon" />
        <span>kiki@kikitransporti.hr</span>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "mobile-menu",
  methods: {
    hideMenu() {
      $("#menu-mobile").hide();
      $(".hamburger").fadeIn();
    }
  }
};
</script>
