<template>
  <div>
    <header-others></header-others>
    <main>
      <section class="section-organize">
        <h1>Organizacija prijevoza</h1>
        <ul>
          <li>
            <img src="@/assets/img/arrow-icon.svg" alt="Arrow Icon" />
            <span>Usluga organizacije domaćeg i međunarodnog prijevoza</span>
          </li>
          <li>
            <img src="@/assets/img/arrow-icon.svg" alt="Arrow Icon" />
            <span>Puni i/ili djelomični utovari 24t</span>
          </li>
          <li>
            <img src="@/assets/img/arrow-icon.svg" alt="Arrow Icon" />
            <span>Jumbo/Mega kamioni utovarne visine do 3m</span>
          </li>
          <li>
            <img src="@/assets/img/arrow-icon.svg" alt="Arrow Icon" />
            <span>Manja vozila nosivosti do 3.5t</span>
          </li>
          <li>
            <img src="@/assets/img/arrow-icon.svg" alt="Arrow Icon" />
            <span>Kombi vozila s 5 paletnih mjesta</span>
          </li>
          <li>
            <img src="@/assets/img/arrow-icon.svg" alt="Arrow Icon" />
            <span>
              Prijevoz rasutih tereta sa silo cisternama obujma 39-60m3 i
              nosivosti 24-27t
            </span>
          </li>
        </ul>
        <div class="organize-cta">
          <p>
            Želite organizirati prijevoz ili imate dodatnih pitanja? Javite se!
          </p>
          <router-link class="button-orange" to="/kontakt"
            >Pošaljite upit</router-link
          >
        </div>
      </section>
    </main>
    <footer-kiki></footer-kiki>
  </div>
</template>

<script>
import $ from "jquery";
import { TweenLite } from "gsap";
import HeaderOthers from "@/components/HeaderOthers.vue";
import FooterKiki from "@/components/FooterKiki.vue";

export default {
  name: "organize",
  components: {
    HeaderOthers,
    FooterKiki
  },
  mounted() {
    TweenLite.fromTo(
      ".section-organize",
      0.4,
      {
        autoAlpha: 0,
        bottom: -20
      },
      {
        autoAlpha: 1,
        bottom: 0
      }
    );
    this.$nextTick(this.animateElementsOnScroll);
  },
  methods: {
    animateElementsOnScroll() {
      var width = window.innerWidth;
      if (width < 550) {
        //animation cta
        const t1 = TweenLite.fromTo(
          $(".organize-cta"),
          0.6,
          { autoAlpha: 0, bottom: -20 },
          { autoAlpha: 1, bottom: 0 }
        );
        const s1 = new this.$scrollmagic.Scene({
          triggerElement: ".section-organize ul",
          offset: 270
        }).setTween(t1);
        this.$ksvuescr.$emit("addScene", "animateElementsOnScroll", s1);
      }
    }
  },
  metaInfo: {
    title: "Organizacija domaćeg i međunarodnog prijevoza robe",
    titleTemplate: "%s - Kiki transporti",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Domaći i međunarodni prijevoz robe, puni i djelomični utovari, jumbo kamioni visine do 3m, prijevoz rasutih tereta sa silo cisternama te kombi i manja vozila."
      },
      {
        property: "og:title",
        content:
          "Organizacija domaćeg i međunarodnog prijevoza robe - Kiki transporti"
      },
      { property: "og:site_name", content: "Gepek" },
      { property: "og:type", content: "website" },
      {
        property: "og:url",
        content: "https://kikitransporti.hr/organizacija-prijevoza"
      },
      {
        property: "og:image",
        content:
          "https://kikitransporti.hr/img/kiki-transporti-header-2.1da1379a.jpg"
      },
      {
        property: "og:description",
        content:
          "Domaći i međunarodni prijevoz robe, puni i djelomični utovari, jumbo kamioni visine do 3m, prijevoz rasutih tereta sa silo cisternama te kombi i manja vozila."
      },
      // Twitter card
      { name: "twitter:card", content: "summary" },
      {
        name: "twitter:site",
        content: "https://kikitransporti.hr/organizacija-prijevoza"
      },
      {
        name: "twitter:title",
        content:
          "Organizacija domaćeg i međunarodnog prijevoza robe - Kiki transporti"
      },
      {
        name: "twitter:description",
        content:
          "Domaći i međunarodni prijevoz robe, puni i djelomični utovari, jumbo kamioni visine do 3m, prijevoz rasutih tereta sa silo cisternama te kombi i manja vozila."
      },
      {
        name: "twitter:image:src",
        content:
          "https://kikitransporti.hr/img/kiki-transporti-header-2.1da1379a.jpg"
      },
      // Google / Schema.org markup:
      {
        itemprop: "name",
        content:
          "Organizacija domaćeg i međunarodnog prijevoza robe - Kiki transporti"
      },
      {
        itemprop: "description",
        content:
          "Domaći i međunarodni prijevoz robe, puni i djelomični utovari, jumbo kamioni visine do 3m, prijevoz rasutih tereta sa silo cisternama te kombi i manja vozila."
      },
      {
        itemprop: "image",
        content:
          "https://kikitransporti.hr/img/kiki-transporti-header-2.1da1379a.jpg"
      }
    ],
    links: [
      {
        rel: "canonical",
        href: "https://kikitransporti.hr/organizacija-prijevoza"
      }
    ]
  }
};
</script>
