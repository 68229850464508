<template>
  <div>
    <header-others></header-others>
    <main>
      <section class="section-ekspres">
        <h1>Ekspres prijevoz robe do 1.4t</h1>
        <div class="content-text">
          <p>
            Svi znamo koliko je važno da vaša roba ili proizvod stigne na
            željeno odredište sigurno, pouzdano i u točno zadanom terminu.
          </p>
          <p>
            Stoga vam pružamo usluge ekspres prijevoza kako bi vaša roba u
            najkraćem vremenu stigla do vaših klijenata ili partnera.
          </p>
          <p>
            Ako imate potrebu za ekspres prijevozom, obratite nam se s
            povjerenjem jer brzina i savršenstvo idu zajedno.
          </p>
        </div>
        <div class="velicina-tereta">
          <div class="triangle-header-wrapper">
            <div class="triangle-header">
              <img src="@/assets/img/teret-icon.svg" alt="Teret Icon" />
              <h2>Dimenzije tereta</h2>
            </div>
          </div>
          <div class="triangle">
            <div class="horizontal-line"></div>
            <div class="triangle-empty"></div>
            <div class="circle circle-1">
              <div class="circle-inner">
                <span class="key">Dužina</span>
                <span class="value">4.5m</span>
              </div>
            </div>
            <div class="circle circle-2">
              <div class="circle-inner">
                <span class="key">Širina</span>
                <span class="value">2.2m</span>
              </div>
            </div>
            <div class="circle circle-3">
              <div class="circle-inner">
                <span class="key">Visina</span>
                <span class="value">2.4m</span>
              </div>
            </div>
          </div>
        </div>
        <ul class="content-cta">
          <div class="cta">
            <router-link to="/organizacija-prijevoza">
              <div class="box">
                <img
                  src="@/assets/img/organize-icon.svg"
                  alt="Organize Transport Icon"
                />
              </div>
              <span>
                Ekspres prijevoz nije ono što tražite? Pogledajte usluge
                organizacije drugih vrsta prijevoza robe!
              </span>
            </router-link>
          </div>
        </ul>
      </section>
    </main>
    <footer-kiki></footer-kiki>
  </div>
</template>

<script>
import $ from "jquery";
import { TweenLite } from "gsap";
import HeaderOthers from "@/components/HeaderOthers.vue";
import FooterKiki from "@/components/FooterKiki.vue";

export default {
  name: "ekspres",
  components: {
    HeaderOthers,
    FooterKiki
  },
  mounted() {
    TweenLite.fromTo(
      ".section-ekspres",
      0.4,
      {
        autoAlpha: 0,
        bottom: -20
      },
      {
        autoAlpha: 1,
        bottom: 0
      }
    );
    this.$nextTick(this.animateElementsOnScroll);
  },
  destroyed() {
    this.$ksvuescr.$emit("destroy");
  },
  methods: {
    animateElementsOnScroll() {
      var width = window.innerWidth;
      if (width < 550) {
        //animation triangle
        const t1 = TweenLite.fromTo(
          $(".velicina-tereta"),
          0.6,
          { autoAlpha: 0, bottom: -20 },
          { autoAlpha: 1, bottom: 0 }
        );
        const s1 = new this.$scrollmagic.Scene({
          triggerElement: ".content-text",
          offset: 150
        }).setTween(t1);
        this.$ksvuescr.$emit("addScene", "animateElementsOnScroll", s1);

        //animation cta
        const t2 = TweenLite.fromTo(
          $(".content-cta"),
          0.6,
          { autoAlpha: 0, bottom: -20 },
          { autoAlpha: 1, bottom: 0 }
        );
        const s2 = new this.$scrollmagic.Scene({
          triggerElement: ".content-cta",
          offset: -200
        }).setTween(t2);
        this.$ksvuescr.$emit("addScene", "animateElementsOnScroll", s2);
      }
    }
  },
  metaInfo: {
    title:
      "Ekspres prijevoz robe do 1.4t, dužine 4.5m, širine 2.2m i visine 2.4m",
    titleTemplate: "%s - Kiki transporti",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Usluga ekspres prijevoza robe omogućava vam da vaša roba u najkraćem vremenu stigne do vaših klijenata ili partnera pouzdano i sigurno."
      },

      {
        property: "og:title",
        content:
          "Ekspres prijevoz robe do 1.4t, dužine 4.5m, širine 2.2m i visine 2.4m - Kiki transporti"
      },
      { property: "og:site_name", content: "Gepek" },
      { property: "og:type", content: "website" },
      {
        property: "og:url",
        content: "https://kikitransporti.hr/ekspres-prijevoz"
      },
      {
        property: "og:image",
        content:
          "https://kikitransporti.hr/img/kiki-transporti-header-1.b80196ac.jpg"
      },
      {
        property: "og:description",
        content:
          "Usluga ekspres prijevoza robe omogućava vam da vaša roba u najkraćem vremenu stigne do vaših klijenata ili partnera pouzdano i sigurno."
      },

      // Twitter card
      { name: "twitter:card", content: "summary" },
      {
        name: "twitter:site",
        content: "https://kikitransporti.hr/ekspres-prijevoz"
      },
      {
        name: "twitter:title",
        content:
          "Ekspres prijevoz robe do 1.4t, dužine 4.5m, širine 2.2m i visine 2.4m - Kiki transporti"
      },
      {
        name: "twitter:description",
        content:
          "Usluga ekspres prijevoza robe omogućava vam da vaša roba u najkraćem vremenu stigne do vaših klijenata ili partnera pouzdano i sigurno."
      },
      {
        name: "twitter:image:src",
        content:
          "https://kikitransporti.hr/img/kiki-transporti-header-1.b80196ac.jpg"
      },

      // Google / Schema.org markup:
      {
        itemprop: "name",
        content:
          "Ekspres prijevoz robe do 1.4t, dužine 4.5m, širine 2.2m i visine 2.4m - Kiki transporti"
      },
      {
        itemprop: "description",
        content:
          "Usluga ekspres prijevoza robe omogućava vam da vaša roba u najkraćem vremenu stigne do vaših klijenata ili partnera pouzdano i sigurno."
      },
      {
        itemprop: "image",
        content:
          "https://kikitransporti.hr/img/kiki-transporti-header-1.b80196ac.jpg"
      }
    ],
    links: [
      { rel: "canonical", href: "https://kikitransporti.hr/ekspres-prijevoz" }
    ]
  }
};
</script>
