<template>
  <header>
    <div class="header-nav header-home">
      <router-link to="/">
        <img
          src="@/assets/img/logo-light.svg"
          alt="Kiki Transporti Logo"
          class="logo-white"
        />
      </router-link>
      <div class="hamburger" @click="showMenu()">
        <div class="lines">
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </div>
      <nav class="nav-desktop">
        <router-link to="/ekspres-prijevoz" active-class="active-link"
          >Ekspres prijevoz</router-link
        >
        <router-link to="/organizacija-prijevoza" active-class="active-link"
          >Organizacija prijevoza</router-link
        >
        <router-link to="/kontakt" active-class="active-link"
          >Kontakt</router-link
        >
      </nav>
    </div>
    <figure id="header-images-slider">
      <div class="img-overlay"></div>
      <div
        id="img-organize"
        class="header-image fade"
        :style="{
          'background-image':
            'url(' + require('@/assets/img/kiki-transporti-header-2.jpg') + ')'
        }"
      ></div>
      <div
        id="img-ekspres"
        class="header-image fade"
        :style="{
          'background-image':
            'url(' + require('@/assets/img/kiki-transporti-header-1.jpg') + ')'
        }"
      ></div>
    </figure>
    <div class="header-homepage-wrapper">
      <div class="header-service-container organize">
        <h2 id="header-service-title">Organizacija prijevoza</h2>
        <p id="header-service-description">
          Organizacija domaćeg ili međunarodnog prijevoza
        </p>
        <router-link to="/organizacija-prijevoza" class="button-orange"
          >Saznajte više</router-link
        >
      </div>
      <div class="header-service-container">
        <h2 id="header-service-title">Ekspres prijevoz</h2>
        <p id="header-service-description">
          Dostava vaše robe u najkraćem roku
        </p>
        <router-link to="/ekspres-prijevoz" class="button-orange"
          >Saznajte više</router-link
        >
      </div>
    </div>
    <div id="wave-header">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style="height: 100%; width: 100%;"
      >
        <path
          d="M-44.86,41.94 C238.99,95.22 262.69,34.03 502.54,53.78 L500.00,150.00 L-48.24,261.02 Z"
          style="stroke: none; fill: #EFF2F5;"
        ></path>
      </svg>
    </div>
  </header>
</template>

<script>
import $ from "jquery";
import { TweenLite } from "gsap";

export default {
  name: "header-home",
  data() {
    return {
      slideIndex: 0,
      destroyed: false
    };
  },
  mounted() {
    TweenLite.fromTo(
      ".header-service-container",
      0.4,
      {
        autoAlpha: 0,
        bottom: -20
      },
      {
        autoAlpha: 1,
        bottom: 0
      }
    );
    this.headerAutoSlider();
    this.removeParallax();
  },
  methods: {
    removeParallax() {
      var isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf("Apple") > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf("CriOS") == -1 &&
        navigator.userAgent.indexOf("FxiOS") == -1;
      if (isSafari == true) {
        $(".header-image").addClass("remove-parallax");
      }
      if (navigator.userAgent.match("CriOS")) {
        $(".header-image").addClass("remove-parallax");
      }
    },
    headerAutoSlider() {
      var i;
      var images = document.getElementsByClassName("header-image");
      var data = document.getElementsByClassName("header-service-container");

      if (images.length > 0 && data.length > 0 && this.destroyed === false) {
        for (i = 0; i < images.length; i++) {
          images[i].style.display = "none";
        }
        for (i = 0; i < data.length; i++) {
          data[i].style.display = "none";
        }

        this.slideIndex++;
        if (this.slideIndex > images.length) {
          this.slideIndex = 1;
        }

        images[this.slideIndex - 1].style.display = "block";
        data[this.slideIndex - 1].style.display = "block";

        var headerImage = $(".header-image");
        TweenLite.fromTo(
          headerImage,
          6,
          { transform: "scale(1)" },
          { transform: "scale(1.06)" }
        );
        setTimeout(this.headerAutoSlider, 6000);
      }
    },
    showMenu() {
      $(".hamburger").hide();
      $("#menu-mobile").fadeIn();
    }
  },
  destroyed() {
    this.destroyed = true;
  }
};
</script>
