<template>
  <main>
    <section class="section-404">
      <h1>Error 404</h1>
      <p>Stranica nije pronađena, vratite se na neku od postojećih stranica!</p>
    </section>
  </main>
</template>

<script>
export default {
  name: "404"
};
</script>
