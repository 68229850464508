import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Ekspres from "./views/Ekspres.vue";
import Organize from "./views/Organize.vue";
import Kontakt from "./views/Kontakt.vue";
import Vue404 from "./views/Vue404.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/ekspres-prijevoz",
      name: "ekspres",
      component: Ekspres
    },
    {
      path: "/organizacija-prijevoza",
      name: "organizacija",
      component: Organize
    },
    {
      path: "/kontakt",
      name: "kontakt",
      component: Kontakt
    },
    {
      path: "/404",
      name: "404",
      component: Vue404
    },
    {
      path: "*",
      redirect: "404"
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
