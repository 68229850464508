<template>
  <div>
    <header-home></header-home>
    <main>
      <section class="section-home">
        <h1>
          Nudimo usluge ekspres prijevoza i organizaciju ostalih vrsti prijevoza
          robe
        </h1>
        <div class="content-text">
          <p>
            Na temelju višegodišnjeg iskustva u prijevozu i logistici, osnovano
            je poduzeće Kiki transporti. Poslovanje je započelo transportom u
            suradnji s poslovnim partnerima čija je proizvodnja usko vezana uz
            autoindustriju.
          </p>
          <p>
            Zahvaljujući tome što u svome radu težimo izvrsnosti te stalnom
            praćenju tržišta, razvijamo se u renomiranu prijevozničku tvrtku.
          </p>
          <p>
            Vrijednosti na kojima temeljimo uspjeh su profesionalnost,
            uslužnost, povjerenje, ekonomičnost, fleksibilnost i sigurnost.
          </p>
        </div>
        <ul class="content-cta">
          <div class="cta">
            <router-link to="/ekspres-prijevoz">
              <div class="box">
                <img src="@/assets/img/van-icon.svg" alt="Van Icon" />
              </div>
              <span>
                Želite da vaša roba stigne na željeno odredište sigurno,
                pouzdano i u točno zadanom terminu?
              </span>
            </router-link>
          </div>
          <div class="cta">
            <router-link to="/organizacija-prijevoza">
              <div class="box">
                <img
                  src="@/assets/img/organize-icon.svg"
                  alt="Organize Transport Icon"
                />
              </div>
              <span>
                Potrebna vam je organizacija domaćeg i međunarodnog prijevoza
                robe?
              </span>
            </router-link>
          </div>
        </ul>
      </section>
    </main>
    <footer-kiki></footer-kiki>
  </div>
</template>

<script>
import $ from "jquery";
import { TweenLite } from "gsap";
import HeaderHome from "@/components/HeaderHome.vue";
import FooterKiki from "@/components/FooterKiki.vue";

export default {
  name: "home",
  components: {
    HeaderHome,
    FooterKiki
  },
  mounted() {
    this.$nextTick(this.animateElementsOnScroll);
  },
  destroyed() {
    this.$ksvuescr.$emit("destroy");
  },
  methods: {
    animateElementsOnScroll() {
      var width = window.innerWidth;
      if (width < 550) {
        //animation section h1
        const t1 = TweenLite.fromTo(
          $(".section-home h1"),
          0.6,
          { autoAlpha: 0, bottom: -20 },
          { autoAlpha: 1, bottom: 0 }
        );
        const s1 = new this.$scrollmagic.Scene({
          triggerElement: "main",
          offset: -150
        }).setTween(t1);
        this.$ksvuescr.$emit("addScene", "animateElementsOnScroll", s1);

        //animation section content text
        const t1p = TweenLite.fromTo(
          $(".content-text"),
          0.6,
          { autoAlpha: 0, bottom: -20 },
          { autoAlpha: 1, bottom: 0 }
        );
        const s1p = new this.$scrollmagic.Scene({
          triggerElement: "main",
          offset: -150
        }).setTween(t1p);
        this.$ksvuescr.$emit("addScene", "animateElementsOnScroll", s1p);

        //animation cta
        const t2 = TweenLite.fromTo(
          $(".content-cta"),
          0.6,
          { autoAlpha: 0, bottom: -20 },
          { autoAlpha: 1, bottom: 0 }
        );
        const s2 = new this.$scrollmagic.Scene({
          triggerElement: ".content-cta",
          offset: -200
        }).setTween(t2);
        this.$ksvuescr.$emit("addScene", "animateElementsOnScroll", s2);
      }

      if (width < 960 || width >= 960) {
        //animation section
        const t1large = TweenLite.fromTo(
          $(".section-home"),
          0.6,
          { autoAlpha: 0, bottom: -20 },
          { autoAlpha: 1, bottom: 0 }
        );
        const s1large = new this.$scrollmagic.Scene({
          triggerElement: "main",
          offset: -330
        }).setTween(t1large);
        this.$ksvuescr.$emit("addScene", "animateElementsOnScroll", s1large);
      }
    }
  },
  metaInfo: {
    title: "Ekspres prijevoz robe i organizacija prijevoza ostalih tereta",
    titleTemplate: "%s - Kiki transporti",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Poduzeće Kiki transporti omogućava vam ekspres prijevoz robe kao i organizaciju drugih vrsti prijevoza tereta. Javite se na +385 91 3778 411."
      },

      {
        property: "og:title",
        content:
          "Ekspres prijevoz robe i organizacija prijevoza ostalih tereta - Kiki transporti"
      },
      { property: "og:site_name", content: "Gepek" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://kikitransporti.hr/" },
      {
        property: "og:image",
        content:
          "https://kikitransporti.hr/img/kiki-transporti-header-1.b80196ac.jpg"
      },
      {
        property: "og:description",
        content:
          "Poduzeće Kiki transporti omogućava vam ekspres prijevoz robe kao i organizaciju drugih vrsti prijevoza tereta. Javite se na +385 91 3778 411."
      },

      // Twitter card
      { name: "twitter:card", content: "summary" },
      { name: "twitter:site", content: "https://kikitransporti.hr/" },
      {
        name: "twitter:title",
        content:
          "Ekspres prijevoz robe i organizacija prijevoza ostalih tereta - Kiki transporti"
      },
      {
        name: "twitter:description",
        content:
          "Poduzeće Kiki transporti omogućava vam ekspres prijevoz robe kao i organizaciju drugih vrsti prijevoza tereta. Javite se na +385 91 3778 411."
      },
      {
        name: "twitter:image:src",
        content:
          "https://kikitransporti.hr/img/kiki-transporti-header-1.b80196ac.jpg"
      },

      // Google / Schema.org markup:
      {
        itemprop: "name",
        content:
          "Ekspres prijevoz robe i organizacija prijevoza ostalih tereta - Kiki transporti"
      },
      {
        itemprop: "description",
        content:
          "Poduzeće Kiki transporti omogućava vam ekspres prijevoz robe kao i organizaciju drugih vrsti prijevoza tereta. Javite se na +385 91 3778 411."
      },
      {
        itemprop: "image",
        content:
          "https://kikitransporti.hr/img/kiki-transporti-header-1.b80196ac.jpg"
      }
    ],
    links: [{ rel: "canonical", href: "https://kikitransporti.hr/" }]
  }
};
</script>
