<template>
  <div>
    <header-others></header-others>
    <main>
      <section class="section-kontakt">
        <h1>Kontakt</h1>
        <p>Javite nam se s povjerenjem:</p>
        <form @submit.prevent="sendEmail">
          <input
            type="text"
            placeholder="Vaše ime..."
            id="kontakt-name"
            required
          />
          <input
            type="email"
            placeholder="Vaša email adresa..."
            id="kontakt-email"
            required
          />
          <textarea
            id="kontakt-content"
            cols="30"
            rows="5"
            placeholder="Vaš upit..."
            required
          ></textarea>
          <span id="alert-message"></span>
          <button type="submit" class="button-orange">Pošaljite upit</button>
        </form>
        <div class="kontakt-wrap">
          <ul class="kontakt-data">
            <li>
              <img src="@/assets/img/phone-icon-black.svg" alt="Phone Icon" />
              <span>+385 91 3778 411</span>
            </li>
            <li>
              <img src="@/assets/img/mail-icon-black.svg" alt="Mail Icon" />
              <span>kiki@kikitransporti.hr</span>
            </li>
            <li>
              <img src="@/assets/img/oib-icon-black.svg" alt="Oib Icon" />
              <span>HR8786039802</span>
            </li>
          </ul>
          <ul class="kontakt-social">
            <li>
              <a href="https://www.facebook.com/kikitransporti" target="_blank">
                <img
                  src="@/assets/img/facebook-icon-black.svg"
                  alt="Facebook Icon"
                />
              </a>
            </li>
          </ul>
        </div>
        <ul class="kontakt-business">
          <li>Kiki transporti - obrt za prijevoz</li>
          <li>vl. Kristijan Petrak</li>
          <li>Gorička ulica 12/1</li>
          <li>49218 Pregrada</li>
        </ul>
      </section>
      <gmap-map :center="center" :zoom="15" id="map">
        <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          @click="center = m.position"
        ></gmap-marker>
      </gmap-map>
    </main>
    <span class="copyright">Copyright © {{ year }}. Kiki transporti</span>
  </div>
</template>

<script>
import $ from "jquery";
import { TweenLite } from "gsap";
import HeaderOthers from "@/components/HeaderOthers.vue";

export default {
  name: "kontakt",
  components: {
    HeaderOthers
  },
  data() {
    return {
      year: "",
      center: { lat: 46.158843, lng: 15.761385 },
      markers: [
        {
          position: { lat: 46.158843, lng: 15.761385 }
        }
      ]
    };
  },
  mounted() {
    this.year = new Date().getFullYear();
    TweenLite.fromTo(
      ".section-kontakt",
      0.4,
      {
        autoAlpha: 0,
        bottom: -20
      },
      {
        autoAlpha: 1,
        bottom: 0
      }
    );
    this.$nextTick(this.animateElementsOnScroll);
  },
  methods: {
    animateElementsOnScroll() {
      var width = window.innerWidth;
      if (width < 550) {
        //animation kontakt data
        const t1 = TweenLite.fromTo(
          $(".kontakt-wrap"),
          0.6,
          { autoAlpha: 0, bottom: -20 },
          { autoAlpha: 1, bottom: 0 }
        );
        const s1 = new this.$scrollmagic.Scene({
          triggerElement: ".section-kontakt form textarea",
          offset: 80
        }).setTween(t1);
        this.$ksvuescr.$emit("addScene", "animateElementsOnScroll", s1);

        //animation kontakt business
        const t2 = TweenLite.fromTo(
          $(".kontakt-business"),
          0.6,
          { autoAlpha: 0, bottom: -20 },
          { autoAlpha: 1, bottom: 0 }
        );
        const s2 = new this.$scrollmagic.Scene({
          triggerElement: ".kontakt-data",
          offset: 30
        }).setTween(t2);
        this.$ksvuescr.$emit("addScene", "animateElementsOnScroll", s2);
      }
    },
    sendEmail() {
      var name = $("#kontakt-name");
      var email = $("#kontakt-email");
      var content = $("#kontakt-content");

      if (name.val() !== "" && email.val() !== "" && content.val() !== "") {
        $.post("mail.php", {
          name: name.val(),
          email: email.val(),
          content: content.val()
        })
          // eslint-disable-next-line
          .done(function(response) {
            $("#kontakt-name").val("");
            $("#kontakt-email").val("");
            $("#kontakt-content").val("");
            $("#alert-message").css("display", "flex");
            $("#alert-message").removeClass("error");
            $("#alert-message").text("Upit je uspješno poslan!");
            setTimeout(function() {
              $("#alert-message").css("display", "none");
            }, 4000);
          })
          // eslint-disable-next-line
          .fail(function(jqXHR, textStatus, errorThrown) {
            $("#kontakt-name").val("");
            $("#kontakt-email").val("");
            $("#kontakt-content").val("");
            $("#alert-message").css("display", "flex");
            $("#alert-message").addClass("error");
            $("#alert-message").text(
              "Došlo je do greške, kontaktirajte nas na kiki@kikitransporti.hr!"
            );
            setTimeout(function() {
              $("#alert-message").css("display", "none");
            }, 8000);
          });
      }
    }
  },
  metaInfo: {
    title: "Kontakt",
    titleTemplate: "%s - Kiki transporti",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Kontaktirajte nas za organizaciju i prijevoz robe. Broj telefona: +385 91 3778 411. Email: kiki@kikitransporti.hr"
      },

      {
        property: "og:title",
        content: "Kontakt - Kiki transporti"
      },
      { property: "og:site_name", content: "Gepek" },
      { property: "og:type", content: "website" },
      {
        property: "og:url",
        content: "https://kikitransporti.hr/kontakt"
      },
      {
        property: "og:image",
        content:
          "https://kikitransporti.hr/img/kiki-transporti-header-1.b80196ac.jpg"
      },
      {
        property: "og:description",
        content:
          "Kontaktirajte nas za organizaciju i prijevoz robe. Broj telefona: +385 91 3778 411. Email: kiki@kikitransporti.hr"
      },

      // Twitter card
      { name: "twitter:card", content: "summary" },
      {
        name: "twitter:site",
        content: "https://kikitransporti.hr/kontakt"
      },
      {
        name: "twitter:title",
        content: "Kontakt - Kiki transporti"
      },
      {
        name: "twitter:description",
        content:
          "Kontaktirajte nas za organizaciju i prijevoz robe. Broj telefona: +385 91 3778 411. Email: kiki@kikitransporti.hr"
      },
      {
        name: "twitter:image:src",
        content:
          "https://kikitransporti.hr/img/kiki-transporti-header-1.b80196ac.jpg"
      },

      // Google / Schema.org markup:
      {
        itemprop: "name",
        content: "Kontakt - Kiki transporti"
      },
      {
        itemprop: "description",
        content:
          "Kontaktirajte nas za organizaciju i prijevoz robe. Broj telefona: +385 91 3778 411. Email: kiki@kikitransporti.hr"
      },
      {
        itemprop: "image",
        content:
          "https://kikitransporti.hr/img/kiki-transporti-header-1.b80196ac.jpg"
      }
    ],
    links: [{ rel: "canonical", href: "https://kikitransporti.hr/kontakt" }]
  }
};
</script>

<style></style>
