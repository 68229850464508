<template>
  <header>
    <div class="header-nav">
      <router-link to="/">
        <img
          src="@/assets/img/logo-dark.svg"
          alt="Kiki Transporti Logo"
          class="logo-white"
        />
      </router-link>
      <div class="hamburger" @click="showMenu()">
        <div class="lines">
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </div>
      <nav class="nav-desktop">
        <router-link
          class="dark"
          to="/ekspres-prijevoz"
          active-class="active-link"
          >Ekspres prijevoz</router-link
        >
        <router-link
          class="dark"
          to="/organizacija-prijevoza"
          active-class="active-link"
          >Organizacija prijevoza</router-link
        >
        <router-link class="dark" to="/kontakt" active-class="active-link"
          >Kontakt</router-link
        >
      </nav>
    </div>
  </header>
</template>

<script>
import $ from "jquery";

export default {
  name: "header-others",
  methods: {
    showMenu() {
      $(".hamburger").hide();
      $("#menu-mobile").fadeIn();
    }
  }
};
</script>
